import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { httpInterceptorProviders } from './http-interceptors';

import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './components/app.component';
import { HttpClientService } from './http-client.service';
import { environment } from '../environments/environment';
import { SwitchGroupsComponent } from './components/switch-groups/switch-groups.component';
import { SharedModule } from './shared/shared.module';
import { ScheduledMaintainanceComponent } from './components/scheduled-maintainance/scheduled-maintainance.component';
import { StoreModule } from '@ngrx/store';

import { AuthService } from './core/auth.service';
import {AuthenticationGuard} from  'microsoft-adal-angular6';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalCustomNavigationClient, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { EventType, InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { provideHttpClient } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MsalService } from '@azure/msal-angular';
import { AuthorizationInterceptor } from './http-interceptors/authorization-interceptor';



export function getAdalConfig() {
  return {
    tenant: environment.adalConfig.tenant,
    clientId: environment.adalConfig.clientId,
    endpoints: environment.adalConfig.endpoints,
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
    cacheLocation: 'sessionStorage'
  };
}





@NgModule({

  declarations: [
    AppComponent,
    SwitchGroupsComponent,
    ScheduledMaintainanceComponent
    
  ],
  imports: [
    SharedModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    AppRoutingModule,
    CoreModule,
    MatSnackBarModule,
  
    
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (handler: HttpBackend) => new TranslateHttpLoader(new HttpClient(handler), './assets/i18n/', '.json'),
        deps: [HttpBackend]
      }
    }),

    MsalModule.forRoot(
       new PublicClientApplication({
        auth: {
          clientId:  environment.adalConfig.clientId,
          authority: 'https://login.microsoftonline.com/'+ environment.adalConfig.tenant,
          redirectUri:  window.location.origin

        },
        cache: {
          cacheLocation: "sessionStorage"
      
        },
        
      }),
     
      {
        interactionType: InteractionType.Redirect, // Msal Guard Configuration
        authRequest: {
          scopes:["user.read"]
        },
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([
          ["https://graph.microsoft.com/v1.0/me", ["offline_access","openid","profile"]]
        ]),
      }
    ),
  ],
  providers: [
    httpInterceptorProviders,
    { provide: HttpClientService, useFactory: (http: HttpClient, router: Router) => new HttpClientService(http, router), deps: [HttpClient, Router] },
    MsalGuard
  ],

  bootstrap: [AppComponent],
  exports: [ CommonModule,
    FormsModule ]
})

 export class AppModule { }
 


