import { Component, HostListener, NgModule, OnInit } from '@angular/core';
import { UserService } from '../core/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../core/auth.service';
import { appVariables } from '../app.constants';
import * as _ from 'lodash';
import { ApplicationInsightsService } from '../shared/application-insights.service';
import { IUser } from "../core/interfaces/app.interface";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';


import { OnDestroy, Inject } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CommonModule } from '@angular/common';



@Component({
  selector: 'app-root',
  template: `
    <app-switch-groups *ngIf="loadSwitchUserGroup" (userGroup)="authenticateUser($event.isTM, $event.email)"></app-switch-groups>
    <app-spinner *ngIf="isBusy" [isbusy]="isBusy"></app-spinner>
    <router-outlet *ngIf="!loadSwitchUserGroup"></router-outlet>`
})
export class AppComponent implements OnInit {

  loadSwitchUserGroup = false;
  isBusy = false; 
  isScheduledMaintainance = false;


  loginDisplay = false;
  

  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.closeAlert();
  }


  constructor(public msalService: MsalService, private authService: AuthService, private userService: UserService,
              private router: Router, private route: ActivatedRoute, private snackBar: MatSnackBar, private applicationInsightsService: ApplicationInsightsService) {
                this.scheduledMaintainance(false);         
  }

  ngOnInit(): void {
    //this.scheduledMaintainance(true);
    if (_.includes(window.location.hash, 'shipto')) {
      this.route.paramMap.subscribe(params => {
        const shipToSearchValue = params.get('shipto');
        const countrySearchValue = this.route.snapshot.queryParamMap.get('country');
        if(shipToSearchValue && countrySearchValue) {
          sessionStorage.setItem(appVariables.guids.paramGUID, shipToSearchValue);
          sessionStorage.setItem(appVariables.guids.searchGUID, countrySearchValue);
          this.router.navigate(['']);
        }
      });
    }  


    if (window === window.parent) {
      if (this.msalService.instance.getAllAccounts()[0]?.username && !this.userService.isAuthenticatingUser) {
        this.applicationInsightsService.setUserId(this.msalService.instance.getAllAccounts()[0]?.username);
        if (sessionStorage.getItem(appVariables.guids.paramGUID) && sessionStorage.getItem(appVariables.guids.searchGUID)) {
          this.authenticateUser(false);
        } else if (_.includes(appVariables.adminDevGroup, this.msalService.instance.getAllAccounts()[0]?.username.toLowerCase())) {
          this.router.navigate(['']);
          this.loadSwitchUserGroup = true;
        } else {
          this.authenticateUser(false);
        }
      }
    }
  }
 


  
  authenticateUser(customSwitch: boolean, customSwitchEmail?: string): void {
   let logInEmailAs! :any;
    const currentAccounts = this.msalService.instance.getAllAccounts();
    if (customSwitch) {
      logInEmailAs = customSwitchEmail;
    } else {
      logInEmailAs = currentAccounts[0]?.username;
    }
    this.loadSwitchUserGroup = false;
    this.isBusy = true;
    this.authService.authenticateUser(logInEmailAs,customSwitch).subscribe(() => {
      this.userService.currentUser.email =currentAccounts[0]?.username.toLocaleLowerCase();
      if (this.userService.currentUser && this.userService.currentUser.email) {
        this.isBusy = false;
        this.router.navigate([appVariables.landingPageUrl]);
      }
    }, (error: any) => {
      if (error) {
        this.isBusy = false;
        this.snackBar.open("Something went wrong. We're working on it and we'll get it fixed soon. Please try again later.", 'Close', {});
      }
    });
  
  }


  closeAlert() {
    if (this.snackBar._openedSnackBarRef) {
      this.snackBar.dismiss();
    }
  }

  scheduledMaintainance(isMaintainance?: boolean): void {
    if(isMaintainance){
      this.isScheduledMaintainance = true;
      this.loadSwitchUserGroup=false;
      this.isBusy = false;
    }
    else{
      this.authService.checkScheduledMaintainance().subscribe(() =>{}, (error: any) => {
        if (error) {
          console.log("err", error);
          this.isScheduledMaintainance = true;
          this.loadSwitchUserGroup=false;
          this.isBusy = false;
        }
      });
    }

  }

} 



